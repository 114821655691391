// import { Map } from 'immutable';
import moment from 'moment';
import { isEmpty, isString, isObject, isArray, isNaN } from 'lodash';
import appAction from '@iso/redux/app/actions';
import { fileTypes, sheetFTypes } from './utilityData';
import getApiData from './apiHelper';

const { setVideoUploadSize, setImageUploadSize } = appAction;

export function clearToken() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('user_data');
}

export function getToken() {
  try {
    const idToken = localStorage.getItem('id_token');
    return idToken;
  } catch (err) {
    clearToken();
    return '';
  }
}

export function checkCsv(file) {
  const fType = file && file.type ? file.type : '';
  const isExcelCsv = sheetFTypes.includes(fType);
  const isLt10M = file.size / 1024 / 1024 < 10;

  let message = '';
  let status = true;

  if (!isExcelCsv) {
    message = 'You can upload only CSV file';
    status = false;
  } else if (!isLt10M) {
    message = 'File must be smaller than 10MB!';
    status = false;
  }

  return { message, status };
}
export function getLanguage() {
  try {
    const language = localStorage.getItem('language');
    return language;
  } catch (err) {
    return '';
  }
}

export function getCountry() {
  try {
    const country = localStorage.getItem('country');
    const parsedCountry = country ? JSON.parse(country) : {};
    return parsedCountry;
  } catch (err) {
    return {};
  }
}
export function getUserData() {
  try {
    const userData = localStorage.getItem('user_data');
    return userData;
  } catch (err) {
    clearToken();
    return {};
  }
}

export function arrayEqual(array1, array2) {
  return array1.sort().toString() == array2.sort().toString();
}

export function timeDifference(givenTime) {
  givenTime = new Date(givenTime);
  const milliseconds = new Date().getTime() - givenTime.getTime();
  const numberEnding = (number) => {
    return number > 1 ? 'e' : '';
  };
  const number = (num) => (num > 9 ? `${num}` : `0${num}`);
  const getTime = () => {
    let temp = Math.floor(milliseconds / 1000);
    const years = Math.floor(temp / 31536000);
    if (years) {
      const month = number(givenTime.getUTCMonth() + 1);
      const day = number(givenTime.getUTCDate());
      const year = givenTime.getUTCFullYear() % 100;
      return `${day}-${month}-${year}`;
    }
    const days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return `vor ${days} tag${numberEnding(days)}`;
      }
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const month = months[givenTime.getUTCMonth()];
      const day = number(givenTime.getUTCDate());
      return `${day} ${month}`;
    }
    const hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return `vor ${hours} stund${numberEnding(hours)}`;
    }
    const minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return `vor ${minutes} minute${numberEnding(minutes)}`;
    }
    return 'vor ein paar Sekunden';
  };
  return getTime();
}

export function stringToInt(value, defValue = 0) {
  if (!value) {
    return 0;
  }
  if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
export function stringToPosetiveInt(value, defValue = 0) {
  const val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getParsedJson(json) {
  if (isString(json) && !isEmpty(json)) return JSON.parse(json);
  return json;
}
export function getSlug(string, type) {
  if (!isEmpty(type)) {
    return string
      .toString()
      .trim()
      .toLowerCase()
      .replace('&', 'and')
      .replace(/[&_\/\\#,+()$~%.'":*?<>{}]/g, '-')
      .replace(/\s+/g, '_')
      .replace(/[^\w\-]+/g, '')
      .replace(/\-\-+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');
  }
  return string
    .toString()
    .trim()
    .toLowerCase()
    .replace('&', 'and')
    .replace(/[&_\/\\#,+()$~%.'":*?<>{}]/g, '-')
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

// Function for generating log text
export function getLogText(log, name = 'Buyer') {
  let text = '';
  const sellerName = log.seller || 'Seller';
  if (log.user_type === 'seller') {
    if (log.log_type === 'seller_view') {
      text = `${sellerName} view this job.`;
    } else if (log.log_type === 'email_send') {
      text = `${sellerName} sended the mail.`;
    } else if (log.log_type === 'sms_send') {
      text = `${sellerName} sended the SMS.`;
    } else if (log.log_type === 'quick_response') {
      text = `${sellerName} sended the Quick Response.`;
    }
  } else if (log.user_type === 'buyer') {
    if (log.log_type === 'view') {
      text = `${name} viewd the profile of ${sellerName} .`;
    } else if (log.log_type === 'quote') {
      text = `${name} requested ${sellerName} for Quote.`;
    } else if (log.log_type === 'buyer_cancel') {
      text = `${name} Cancelled this job.`;
    }
  }
  return text;
}

// table log
export function getActivity(type) {
  let text = '';
  if (type === 'view') {
    text = 'Buyer viewd your profile';
  } else if (type === 'seller_view') {
    text = 'Seller viewd this job';
  } else if (type === 'email_send') {
    text = 'Seller contact the buyer via Email';
  } else if (type === 'sms_send') {
    text = 'Seller contact the buyer via SMS';
  } else if (type === 'quick_response') {
    text = `Seller sended the Quick Response.`;
  }

  return text;
}

export function getHours() {
  const timesArr = [];
  for (let i = 0; i <= 24; i += 1) {
    if (i < 10) {
      timesArr.push({
        value: `0${i}:00`,
        label: `0${i}:00`,
      });
    } else if (i === 24) {
      timesArr.push({
        value: `23:59`,
        label: `23:59`,
      });
    } else {
      timesArr.push({
        value: `${i}:00`,
        label: `${i}:00`,
      });
    }
  }
  return timesArr;
}

export function disabledDate(current, type) {
  // if (type === 'today') {
  // Disable previous dates
  return current < moment().startOf('day');
  // }
  // Disable previous dates + today
  // return current && current < moment().endOf('day');
}

export function disableFutureData(current) {
  return current > moment().endOf('day');
}
export function getFullName(fname, lname) {
  if (fname && lname) return `${fname} ${lname}`;
  if (fname) return fname;
  return '';
}

export function dropdownFilter(input, option) {
  return option.props.children
    ? option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    : false;
}
export function countryFilter(input, option) {
  return (
    option.props.countryname.toLowerCase().indexOf(input.toLowerCase()) >= 0
  );
}
export function languageFilter(input, option) {
  return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export function chatFilesVal(type) {
  const fTypes = isObject(fileTypes) ? fileTypes : {};
  if (fTypes[type]) {
    return true;
  }
  return false;
}

export function formatFileSize(bytes, decimalPoint = 2) {
  if (bytes == 0) return '0 Bytes';
  const k = 1000;
  const dm = decimalPoint || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function bookingDateFormat(nxtBooking = {}) {
  let dateTime = '-';
  const nextAppointment = isObject(nxtBooking) ? nxtBooking : {};
  const sTime = nextAppointment.sTime ? ` ${nextAppointment.sTime}` : '';
  if (!isEmpty(nextAppointment) && sTime) {
    const fmDate = `${nextAppointment.date}${sTime}`;
    dateTime = moment(fmDate).format('DD-MM-YYYY [um] hh:mm [Uhr]');
  }
  return dateTime;
}

/**
 * @function checkImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkImage(file, size = 10) {
  const isJpgOrPng =
    file.type === 'image/jpeg' ||
    file.type === 'image/png' ||
    file.type === 'image/jpg';
  let message = '';
  let status = true;
  const isLt2M = file.size / 1024 / 1024 < Number(size);
  if (!isJpgOrPng) {
    message = 'You can only upload JPG/PNG/JPEG file!';
    status = false;
  } else if (!isLt2M) {
    message = `Image must smaller than ${size}MB!`;
    status = false;
  }

  return { message, status };
}

/**
 * @function checkImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkVideo(file, size) {
  let message = '';
  let status = true;
  const isLt10M = file.size / 1024 / 1024 < size;
  if (!isLt10M) {
    message = `Video must smaller than ${size}MB!`;
    status = false;
  }

  return { message, status };
}

/**
 * @function checkSvgImage
 * @param {Object} file Check Image type and Sixe and return Message & Status
 *
 */
export function checkSvgImage(file) {
  const isJpgOrPng = file.type === 'image/svg+xml' || file.type === 'image/svg';
  let message = '';
  let status = true;
  const isLt2M = file.size / 1024 / 1024 < 5;
  if (!isJpgOrPng) {
    message = 'You can only upload SVG file!';
    status = false;
  } else if (!isLt2M) {
    message = 'Image must smaller than 2MB!';
    status = false;
  }
  return { message, status };
}

export function fixAutocomplete() {
  if (document) {
    document.querySelectorAll('input').forEach((e) => {
      // you can put any value but NOT "off" or "false" because they DO NOT works
      e.setAttribute('autocomplete', 'stopAutocomplete');
    });
  }
}

export function getCordZip(res) {
  if (isObject(res) && !isEmpty(res)) {
    const coordinates =
      res && isObject(res.location) && isArray(res.location.coordinates)
        ? res.location.coordinates
        : [];
    let location = {};
    if (!isEmpty(coordinates)) {
      location = {
        lat: coordinates[0],
        lng: coordinates[1],
      };
    }
    return location;
  }
  return {};
}

/**
 * @function getSortOrder
 * @param {string} order Return type of for sorting for API
 *
 */
export function getSortOrder(order) {
  if (order === 'ascend') {
    return 'ASC';
  }
  if (order === 'descend') {
    return 'DESC';
  }
  return order;
}

/**
 * Return Allowed Permission to User as Array
 * @function getPermissionArray
 * @param {object} data It is UserData which is saved in Redux at the Login time
 *
 */
export function getPermissionArray(data) {
  let permAray = [];
  if (!isEmpty(data) && !isEmpty(data.permissions)) {
    permAray = data.permissions.split('|');
  }
  return permAray;
}
/**
 * Return Static Permission array
 * While creating New Admin
 * @function displayPermissionList
 *
 */
export function displayPermissionList() {
  return [
    'admin_view',
    'admin_add',
    'admin_edit',
    'admin_delete',
    'adminlog_view',
    'adminlog_add',
    'adminlog_edit',
    'adminlog_delete',
    'emailtemplate_view',
    'emailtemplate_add',
    'emailtemplate_edit',
    'emailtemplate_delete',
    'user_view',
    'user_add',
    'user_edit',
    'user_delete',
    'solicitor_view',
    'solicitor_add',
    'solicitor_edit',
    'solicitor_delete',
    'office_view',
    'office_add',
    'office_edit',
    'office_delete',
    'review_view',
    'review_add',
    'review_edit',
    'review_delete',
    'reviewanswer_view',
    'reviewanswer_add',
    'reviewanswer_edit',
    'reviewanswer_delete',
    'claim_view',
    'claim_add',
    'claim_edit',
    'claim_delete',
    'achievement_view',
    'achievement_add',
    'achievement_edit',
    'achievement_delete',
    'solicitorachievement_view',
    'solicitorachievement_add',
    'solicitorachievement_edit',
    'solicitorachievement_delete',
    'award_view',
    'award_add',
    'award_edit',
    'award_delete',
    'plan_view',
    'plan_add',
    'plan_edit',
    'plan_delete',
    'planoption_view',
    'planoption_add',
    'planoption_edit',
    'planoption_delete',
    'agreement_view',
    'agreement_add',
    'agreement_edit',
    'agreement_delete',
    'transaction_view',
    'transaction_add',
    'transaction_edit',
    'transaction_delete',
    'webhook_view',
    'webhook_add',
    'webhook_edit',
    'webhook_delete',
    'country_view',
    'country_add',
    'country_edit',
    'country_delete',
    'county_view',
    'county_add',
    'county_edit',
    'county_delete',
    'city_view',
    'city_add',
    'city_edit',
    'city_delete',
    'question_view',
    'question_add',
    'question_edit',
    'question_delete',
    'answer_view',
    'answer_add',
    'answer_edit',
    'answer_delete',
    'law_view',
    'law_add',
    'law_edit',
    'law_delete',
    'citylaw_view',
    'citylaw_add',
    'citylaw_edit',
    'citylaw_delete',
    'guide_view',
    'guide_add',
    'guide_edit',
    'guide_delete',
    'offer_view',
    'offer_add',
    'offer_edit',
    'offer_delete',
    'tag_view',
    'tag_add',
    'tag_edit',
    'tag_delete',
    'page_view',
    'page_add',
    'page_edit',
    'page_delete',
    'referrer_view',
    'referrer_add',
    'referrer_edit',
    'referrer_delete',
    'campaign_view',
    'campaign_add',
    'campaign_edit',
    'campaign_delete',
    'settings_view',
    'settings_add',
    'settings_edit',
    'settings_delete',
    'settingsgroup_view',
    'settingsgroup_add',
    'settingsgroup_edit',
    'settingsgroup_delete',
  ];
}

export function getPermArr() {
  return [
    'Dashboard',
    'Business(AdminAccess)',
    'ManageTeam',
    'JobApplications',
    'ClaimedProviders',
    'UnclaimedProviders',
    'Clients',
    'Professionals',
    'ClientRewardsManageUsers',
    'WaysToEarn',
    'AboutRewardFAQ',
    'Businesses',
    'Bookings',
    'ServiceTypes',
    'ServiceDescriptions',
    'NewServiceRequests',
    'ServiceNames',
    'DealNames',
    'AddOnServices',
    'AddOnServicesBanner',
    'AddOnServicesFAQ',
    'RejuuvAds',
    'ProviderRewards',
    'ProviderRewardsRedemptions',
    'ProviderRewardsBanner',
    'ProviderRewardsFAQ',
    'AnnouncementPopUp',
    'ProviderMembershipPlans',
    'ProviderMembershipKeyFeatures',
    'ProviderMembershipCategories',
    'ProviderMembershipFAQ',
    'StockLibraryCategories',
    'StockLibraryImages',
    'StockLibraryDGTCoverImages',
    'AvatarLibrary',
    'EmailTemplates',
    'GiftCardCategories',
    'GiftCardDesigns',
    'GiftCardGuidelines',
    'GiftCardProviderFAQ',
    'GiftCardProviderWebsite',
    'Badges',
    'BadgesRequests',
    'Reviews',
    'YelpRequests',
    'ReviewRemovalRequests',
    'CMS',
    'ProviderLoyaltyFAQ',
    'PreviewWebsite',
    'ProviderOnboarding',
    'WebsiteTagLineOptions',
    'AboutUsTemplates',
    'SocialMediaOptions',
    'BusinessTheme',
    'ProviderPortalHomeCards',
    'ServiceTimeSettings',
    'StateTaxes',
    'VisitorCountInflator',
    'MembershipIntroVideo',
    'OnboardingVideos',
    'DeleteReason',
    'ReportCustomer',
    'ReviewReport',
    'CloseDeactiveReason',
    'HealthAndSafety',
    'DeactivateBusinessRequests',
    'CloseAccountRequests',
    'TranslationLanguages',
    'TranslationProduct',
    'TranslationMarketing',
    'Settings',
    'CSVUpload',
    'NavigationMenu',
    'HeroSection',
    'ProviderProfessional',
    'DealCardSection',
    'DiscoverSection',
    'CustomerPromoVideo',
    'LoyaltySection',
    'PopularMassageSection',
    'GiftCard',
    'AboutUS',
    'HomeScreenSection',
    'Location',
    'GrowBusinessSection',
    'WebsiteThemes',
    'BusinessHeroSection',
    'PromoVideoSection',
    'GroupFeatures',
    'MemberBenefitsSection',
    'SplitScreenSection',
    'HeroSection',
    'VisionaryPartnership',
    'BusinessPartnership',
    'HiringSection',
    'GrowwBusiness',
    'HeroSection',
    'GiveawaySection',
    'RaffleSection',
    'LoyalMember',
    'EarningWays',
    'RejuuvCredits',
    'SavingSummary',
    'LoyaltyCTA',
    'MarketingLoyaltyFAQ',
    'DealsHero',
    'DealsMainSection',
    'RewardsHero',
    'RewardsCategories',
    'MarketingReward',
    'RewardsFAQs',
    'DGTSettings',
    'ErrorLog',
  ];
}
export function priorityArray() {
  return [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
}

export function colorArray() {
  return [
    { name: 'A', Color: '#1abc9c' },
    { name: 'B', Color: '#f56a00' },
    { name: 'C', Color: '#c0392b' },
    { name: 'D', Color: '#3498db' },
    { name: 'E', Color: '#9b59b6' },
    { name: 'F', Color: '#34495e' },
    { name: 'G', Color: '#16a085' },
    { name: 'H', Color: 'rgba(63, 81, 181,1.0)' },
    { name: 'I', Color: '#2980b9' },
    { name: 'J', Color: '#8e44ad' },
    { name: 'K', Color: '#2c3e50' },
    { name: 'L', Color: '#f1c40f' },
    { name: 'F', Color: '#e74c3c' },
    { name: 'M', Color: '#f39c12' },
    { name: 'N', Color: '#273c75' },
    { name: 'O', Color: '#c0392b' },
    { name: 'P', Color: '#f39c12' },
    { name: 'Q', Color: '#7265e6' },
    { name: 'R', Color: '#00a8ff' },
    { name: 'S', Color: '#e1b12c' },
    { name: 'T', Color: '#689F38' },
    { name: 'U', Color: '#353b48' },
    { name: 'V', Color: '#dcdde1' },
    { name: 'W', Color: '#c23616' },
    { name: 'X', Color: '#00a8ff' },
    { name: 'Y', Color: '#4cd137' },
    { name: 'Z', Color: '#9c88ff' },
  ];
}

export function positionArray() {
  return [
    { id: 'Partner', name: 'Partner' },
    { id: 'Solicitor', name: 'Solicitor' },
    { id: 'Barrister', name: 'Barrister' },
    { id: 'Chartered Legal Executive', name: 'Chartered Legal Executive' },
    { id: 'Paralegal', name: 'Paralegal' },
    { id: 'Licensed Conveyancer', name: 'Licensed Conveyancer' },
    { id: 'Mediator', name: 'Mediator' },
    { id: 'Litigation Executive', name: 'Litigation Executive' },
  ];
}

export function daysArray() {
  return [
    {
      day: 'Monday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Tuesday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Wednesday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Thursday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Friday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Saturday',
      start: '09:00',
      end: '18:00',
    },
    {
      day: 'Sunday',
      start: 'Closed',
      end: 'Closed',
    },
  ];
}

export function Time() {
  return [
    {
      key: 1,
      label: '12:00 AM',
      value: '12:00 AM',
    },
    {
      key: 2,
      label: '12:15 AM',
      value: '12:15 AM',
    },
    {
      key: 3,
      label: '12:30 AM',
      value: '12:30 AM',
    },
    {
      key: 4,
      label: '12:45 AM',
      value: '12:45 AM',
    },
    {
      key: 5,
      label: '01:00 AM',
      value: '01:00 AM',
    },
    {
      key: 6,
      label: '01:15 AM',
      value: '01:15 AM',
    },
    {
      key: 7,
      label: '01:30 AM',
      value: '01:30 AM',
    },
    {
      key: 8,
      label: '01:45 AM',
      value: '01:45 AM',
    },
    {
      key: 9,
      label: '02:00 AM',
      value: '02:00 AM',
    },
    {
      key: 10,
      label: '02:15 AM',
      value: '02:15 AM',
    },
    {
      key: 11,
      label: '02:30 AM',
      value: '02:30 AM',
    },
    {
      key: 12,
      label: '02:45 AM',
      value: '02:45 AM',
    },
    {
      key: 13,
      label: '03:00 AM',
      value: '03:00 AM',
    },
    {
      key: 14,
      label: '03:15 AM',
      value: '03:15 AM',
    },
    {
      key: 15,
      label: '03:30 AM',
      value: '03:30 AM',
    },
    {
      key: 16,
      label: '03:45 AM',
      value: '03:45 AM',
    },
    {
      key: 17,
      label: '04:00 AM',
      value: '04:00 AM',
    },
    {
      key: 18,
      label: '04:15 AM',
      value: '04:15 AM',
    },
    {
      key: 19,
      label: '04:30 AM',
      value: '04:30 AM',
    },
    {
      key: 20,
      label: '04:45 AM',
      value: '04:45 AM',
    },
    {
      key: 21,
      label: '05:00 AM',
      value: '05:00 AM',
    },
    {
      key: 22,
      label: '05:15 AM',
      value: '05:15 AM',
    },
    {
      key: 23,
      label: '05:30 AM',
      value: '05:30 AM',
    },
    {
      key: 24,
      label: '05:45 AM',
      value: '05:45 AM',
    },
    {
      key: 25,
      label: '06:00 AM',
      value: '06:00 AM',
    },
    {
      key: 26,
      label: '06:15 AM',
      value: '06:15 AM',
    },
    {
      key: 27,
      label: '06:30 AM',
      value: '06:30 AM',
    },
    {
      key: 28,
      label: '06:45 AM',
      value: '06:45 AM',
    },
    {
      key: 29,
      label: '07:00 AM',
      value: '07:00 AM',
    },
    {
      key: 30,
      label: '07:15 AM',
      value: '07:15 AM',
    },
    {
      key: 31,
      label: '07:30 AM',
      value: '07:30 AM',
    },
    {
      key: 32,
      label: '07:45 AM',
      value: '07:45 AM',
    },
    {
      key: 33,
      label: '08:00 AM',
      value: '08:00 AM',
    },
    {
      key: 34,
      label: '08:15 AM',
      value: '08:15 AM',
    },
    {
      key: 35,
      label: '08:30 AM',
      value: '08:30 AM',
    },
    {
      key: 36,
      label: '08:45 AM',
      value: '08:45 AM',
    },
    {
      key: 37,
      label: '09:00 AM',
      value: '09:00 AM',
    },
    {
      key: 38,
      label: '09:15 AM',
      value: '09:15 AM',
    },
    {
      key: 39,
      label: '09:30 AM',
      value: '09:30 AM',
    },
    {
      key: 40,
      label: '09:45 AM',
      value: '09:45 AM',
    },
    {
      key: 41,
      label: '10:00 AM',
      value: '10:00 AM',
    },
    {
      key: 42,
      label: '10:15 AM',
      value: '10:15 AM',
    },
    {
      key: 43,
      label: '10:30 AM',
      value: '10:30 AM',
    },
    {
      key: 44,
      label: '10:45 AM',
      value: '10:45 AM',
    },
    {
      key: 45,
      label: '11:00 AM',
      value: '11:00 AM',
    },
    {
      key: 46,
      label: '11:15 AM',
      value: '11:15 AM',
    },
    {
      key: 47,
      label: '11:30 AM',
      value: '11:30 AM',
    },
    {
      key: 48,
      label: '11:45 AM',
      value: '11:45 AM',
    },
    {
      key: 49,
      label: '12:00 PM',
      value: '12:00 PM',
    },
    {
      key: 50,
      label: '12:15 PM',
      value: '12:15 PM',
    },
    {
      key: 51,
      label: '12:30 PM',
      value: '12:30 PM',
    },
    {
      key: 52,
      label: '12:45 PM',
      value: '12:45 PM',
    },
    {
      key: 53,
      label: '01:00 PM',
      value: '01:00 PM',
    },
    {
      key: 54,
      label: '01:15 PM',
      value: '01:15 PM',
    },
    {
      key: 55,
      label: '01:30 PM',
      value: '01:30 PM',
    },
    {
      key: 56,
      label: '01:45 PM',
      value: '01:45 PM',
    },
    {
      key: 57,
      label: '02:00 PM',
      value: '02:00 PM',
    },
    {
      key: 58,
      label: '02:15 PM',
      value: '02:15 PM',
    },
    {
      key: 59,
      label: '02:30 PM',
      value: '02:30 PM',
    },
    {
      key: 60,
      label: '02:45 PM',
      value: '02:45 PM',
    },
    {
      key: 61,
      label: '03:00 PM',
      value: '03:00 PM',
    },
    {
      key: 62,
      label: '03:15 PM',
      value: '03:15 PM',
    },
    {
      key: 63,
      label: '03:30 PM',
      value: '03:30 PM',
    },
    {
      key: 64,
      label: '03:45 PM',
      value: '03:45 PM',
    },
    {
      key: 65,
      label: '04:00 PM',
      value: '04:00 PM',
    },
    {
      key: 66,
      label: '04:15 PM',
      value: '04:15 PM',
    },
    {
      key: 67,
      label: '04:30 PM',
      value: '04:30 PM',
    },
    {
      key: 68,
      label: '04:45 PM',
      value: '04:45 PM',
    },
    {
      key: 69,
      label: '05:00 PM',
      value: '05:00 PM',
    },
    {
      key: 70,
      label: '05:15 PM',
      value: '05:15 PM',
    },
    {
      key: 71,
      label: '05:30 PM',
      value: '05:30 PM',
    },
    {
      key: 72,
      label: '05:45 PM',
      value: '05:45 PM',
    },
    {
      key: 73,
      label: '06:00 PM',
      value: '06:00 PM',
    },
    {
      key: 74,
      label: '06:15 PM',
      value: '06:15 PM',
    },
    {
      key: 75,
      label: '06:30 PM',
      value: '06:30 PM',
    },
    {
      key: 76,
      label: '06:45 PM',
      value: '06:45 PM',
    },
    {
      key: 77,
      label: '07:00 PM',
      value: '07:00 PM',
    },
    {
      key: 78,
      label: '07:15 PM',
      value: '07:15 PM',
    },
    {
      key: 79,
      label: '07:30 PM',
      value: '07:30 PM',
    },
    {
      key: 80,
      label: '07:45 PM',
      value: '07:45 PM',
    },
    {
      key: 81,
      label: '08:00 PM',
      value: '08:00 PM',
    },
    {
      key: 82,
      label: '08:15 PM',
      value: '08:15 PM',
    },
    {
      key: 83,
      label: '08:30 PM',
      value: '08:30 PM',
    },
    {
      key: 84,
      label: '08:45 PM',
      value: '08:45 PM',
    },
    {
      key: 85,
      label: '09:00 PM',
      value: '09:00 PM',
    },
    {
      key: 86,
      label: '09:15 PM',
      value: '09:15 PM',
    },
    {
      key: 87,
      label: '09:30 PM',
      value: '09:30 PM',
    },
    {
      key: 88,
      label: '09:45 PM',
      value: '09:45 PM',
    },
    {
      key: 89,
      label: '10:00 PM',
      value: '10:00 PM',
    },
    {
      key: 90,
      label: '10:15 PM',
      value: '10:15 PM',
    },
    {
      key: 91,
      label: '10:30 PM',
      value: '10:30 PM',
    },
    {
      key: 92,
      label: '10:45 PM',
      value: '10:45 PM',
    },
    {
      key: 93,
      label: '11:00 PM',
      value: '11:00 PM',
    },
    {
      key: 94,
      label: '11:15 PM',
      value: '11:15 PM',
    },
    {
      key: 95,
      label: '11:30 PM',
      value: '11:30 PM',
    },
    {
      key: 96,
      label: '11:45 PM',
      value: '11:45 PM',
    },
  ];
}

export function techniqueArray() {
  return [
    'Full Body Massage',
    'Acupressure',
    'Back Walking',
    'Chair Massage',
    'Cupping',
    'Deep Tissue',
    'Head Massage',
    'Hot Stone',
    'Prenatal',
    'Reflexology',
    'Swedish',
    'Thai',
    'Trigger Point',
  ];
}

export async function fetchVideoUploadSize(dispatch) {
  try {
    const resMaxVideoUploadSize = await getApiData(
      'getSetting',
      { key: 'video_upload_in_size' },
      'GET',
    );
    const resMaxImageUploadSize = await getApiData(
      'getSetting',
      { key: 'image_upload_size' },
      'GET',
    );
    if (resMaxVideoUploadSize.success && resMaxVideoUploadSize.data) {
      dispatch(setVideoUploadSize(Number(resMaxVideoUploadSize.data.value)));
    }
    if (resMaxImageUploadSize.success && resMaxImageUploadSize.data) {
      dispatch(setImageUploadSize(Number(resMaxImageUploadSize.data.value)));
    }
  } catch (err) {
    console.log('🚀 ~ file: app.js:27 ~ fetchData ~ err:', err);
    // notification({ type: 'error', message: 'Something went wrong.' });
  }
}
